import React, { useState, useEffect } from "react";
import { addLedgerAccount as addLedgerAccountAPI, getLedgerAccounts as getLedgerAccountsAPI } from "../../utils/api";
import MainLayout from "../../layouts";
import LedgerAccountsTable from '../../components/LedgerAccountsTable'
import { TextField, Grid, FormControl, InputLabel, Select, MenuItem, Box, Button } from "@mui/material";
import Modal from '../../components/BaseModal'

function LedgerPage() {
    const [ledgerAccounts, setLedgerAccounts] = useState([])
    const [ledgerAccount, setLedgerAccount] = useState("")
    const [workGroup, setWorkGroup] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const onInputChange = ({ target }) => {
        const name = target.name
        const value = target.value

        eval(`set${capitalizeFirstLetter(name)}`)(value)
    }

    const addLedgerAccount = async () => {
        const payload = { work_group_id: workGroup, description: ledgerAccount }

        try {
            await addLedgerAccountAPI(payload)
        } catch (err) {
            console.log(err)
        }

        toggleModal()

        fetchData()
    }

    function fetchData() {
        setIsLoading(true)
        getLedgerAccountsAPI().then(({ data }) => {
            setLedgerAccounts(data)
            setIsLoading(false)
        }
        ).catch(err => {
            console.log(err)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <MainLayout>
            <LedgerAccountsTable ledgerAccounts={ledgerAccounts} fetchData={fetchData} toggleModal={toggleModal} isLoading={isLoading} />
            <Modal open={showModal} onClose={toggleModal} toggleModal={toggleModal} title="Adicionar Conta Razão">
                <Grid container direction="column" gap={4} style={{ padding: '40px 0 0 0' }}>
                    <TextField
                        id="name"
                        name="ledgerAccount"
                        label="Conta Razão"
                        variant="standard"
                        value={ledgerAccount}
                        style={{ width: '100%' }}
                        onChange={onInputChange}
                    />
                    <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                        <FormControl variant="standard" style={{ width: '100%' }}>
                            <InputLabel id="demo-simple-select-standard-label">Grupo de Trabalho</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                name="workGroup"
                                value={workGroup}
                                onChange={onInputChange}
                            >
                                <MenuItem value="1">ti</MenuItem>
                                <MenuItem value="2">infraestrutura</MenuItem>
                                <MenuItem value="3">gastos_adm</MenuItem>
                                <MenuItem value="4">logistica</MenuItem>
                                <MenuItem value="5">pessoas_e_beneficios</MenuItem>
                                <MenuItem value="6">headcount</MenuItem>
                                <MenuItem value="7">prestacao_de_serviços</MenuItem>
                                <MenuItem value="8">comercial_e_marketing</MenuItem>
                                <MenuItem value="9">manutencao</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Box my={1} />
                    <Button variant="contained" disabled={!(workGroup && ledgerAccount)} onClick={addLedgerAccount}>Adicionar</Button>
                </Grid>
            </Modal>
        </MainLayout>
    )
}

export default LedgerPage