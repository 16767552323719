import React, { createContext, useState } from 'react';
import { identifyAccounts as identifyAccountsAPI } from '../utils/api';

const ProcessFileContext = createContext();

export const ProcessFileContextProvider = ({ children }) => {
    const [uploadedFilePath, setUploadedFilePath] = useState("")
    const [processingSteps, setProcessingSteps] = useState(["PROPOSE_STEP"])
    const [missingColumns, setMissingColumns] = useState([])
    const [identifiedAccounts, setIdentifiedAccounts] = useState([])
    const [processedFilename, setProcessedFilename] = useState("")
    const [responseFileURL, setResponseFileURL] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const identifyAccounts = () => {
        setIsLoading(true)
        identifyAccountsAPI(uploadedFilePath, processingSteps)
            .then(({ data }) => {
                const { missing_columns: missingColumns, identified_accounts: identifiedAccounts } = data
                setMissingColumns(missingColumns)
                setIdentifiedAccounts(identifiedAccounts)
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    }

    return (
        <ProcessFileContext.Provider value={{ uploadedFilePath, setUploadedFilePath, processingSteps, setProcessingSteps, missingColumns, setMissingColumns, identifyAccounts, identifiedAccounts, setProcessedFilename, setResponseFileURL, responseFileURL, processedFilename, isLoading, setIsLoading }}>
            {children}
        </ProcessFileContext.Provider>
    );
};

export default ProcessFileContext;