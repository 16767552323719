import React, { useState, useEffect } from "react";
import { getOutLedgerAccounts as getOutLedgerAccountsAPI, addOutLedgerAccount as addOutLedgerAccountAPI } from "../../utils/api";
import MainLayout from "../../layouts";
import OutLedgerAccountsTable from "../../components/OutLedgerAccountsTable"
import { TextField, Grid, Box, Button } from "@mui/material";
import Modal from '../../components/BaseModal'

function OutLedgerAccountsPage() {
    const [outLedgerAccounts, setOutLedgerAccounts] = useState([])
    const [outLedgerAccount, setOutLedgerAccount] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const onInputChange = ({ target }) => {
        const name = target.name
        const value = target.value

        eval(`set${capitalizeFirstLetter(name)}`)(value)
    }

    const addOutLedgerAccount = async () => {
        const payload = { description: outLedgerAccount }
        try {
            await addOutLedgerAccountAPI(payload)
        } catch (err) {
            console.log(err)
        }

        toggleModal()

        fetchData()
    }

    async function fetchData() {
        setIsLoading(true)
        getOutLedgerAccountsAPI().then(({ data }) => {
            setOutLedgerAccounts(data)
            setIsLoading(false)
        }
        ).catch(err => {
            console.log(err)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <MainLayout>
            <OutLedgerAccountsTable outLedgerAccounts={outLedgerAccounts} fetchData={fetchData} toggleModal={toggleModal} isLoading={isLoading} />
            <Modal open={showModal} onClose={toggleModal} toggleModal={toggleModal} title="Adicionar Conta Fora de escopo">
                <Grid container direction="column" gap={4} style={{ padding: '40px 0 0 0' }}>
                    <TextField
                        id="name"
                        name="outLedgerAccount"
                        label="Conta Fora de Escopo"
                        variant="standard"
                        value={outLedgerAccount}
                        style={{ width: '100%' }}
                        onChange={onInputChange}
                    />
                    <Box my={1} />
                    <Button variant="contained" disabled={!outLedgerAccount} onClick={addOutLedgerAccount}>Adicionar</Button>
                </Grid>
            </Modal>
        </MainLayout>
    )
}

export default OutLedgerAccountsPage