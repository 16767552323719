import { useContext } from 'react'
import { Typography, Card, Grid, Box, Button } from "@mui/material"
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ProcessFileContext from "../../../contexts/processFileContext";
import StepperContext from "../../../contexts/stepperContext";

const ResultsContent = () => {
    const { responseFileURL, processedFilename } = useContext(ProcessFileContext)
    const { resetStepper } = useContext(StepperContext)

    return (
        <Card sx={{ padding: "48px", borderRadius: "8px" }}>
            <Grid container justify="center" direction="column">
                <Box mb={4}>
                    <Typography variant="h6">
                        <strong>
                            Seu arquivo está pronto!
                        </strong>
                    </Typography>
                    <Box mb={1} />
                    <Typography variant="body1" >
                        Clique no botão abaixo para baixar o resultado
                    </Typography>
                    <Box mb={4} />

                    <Button variant="outlined" fullWidth={true} startIcon={<FileDownloadIcon />} href={responseFileURL} download={processedFilename}>
                        Download do Arquivo
                    </Button>
                    <Box mb={8} />
                    <Button variant="contained" fullWidth={true} onClick={resetStepper}>Reiniciar o fluxo</Button>
                </Box>
            </Grid>
        </Card >
    )
}

export default ResultsContent