const UserTypeEnum = {
    ADMIN: 'ADMIN',
    CONSULTANT: 'CONSULTANT'
};

const translateUserType = (value) => {
    const translations = {
        ADMIN: 'Administrador',
        CONSULTANT: 'Consultor'
    };
    return translations[value] || value;
};

export { UserTypeEnum, translateUserType };
