import MainLayout from '../../layouts';
import ProcessFileStepper from '../../components/Main/ProcessFileStepper';


function SimpleAppLayoutMUI() {
    return (
        <MainLayout>
            <ProcessFileStepper />
        </MainLayout >
    );
}

export default SimpleAppLayoutMUI;