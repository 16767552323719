import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import './index.scss'
import { StepperContextProvider } from './contexts/stepperContext';
import { ProcessFileContextProvider } from './contexts/processFileContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StepperContextProvider>
        <ProcessFileContextProvider>
            <Router />
        </ProcessFileContextProvider>
    </StepperContextProvider>
);
