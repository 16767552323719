import { jwtDecode } from 'jwt-decode';
import { UserTypeEnum } from '../enums/userType';

const userInfo = localStorage.getItem('user');

export const userData = () => {
    if (!userInfo)
        return null

    return jwtDecode(userInfo);
};

export const isAdmin = () => {
    const user = userData();

    return user?.user_type === UserTypeEnum.ADMIN;
}

