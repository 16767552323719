import { Alert } from '@mui/material';
import { toast } from 'react-toastify';

export const createAlert = (status, message) => {
    return <Alert severity={status}>{message}</Alert>;
}

export const createNotification = (status, message, isMobile) => {
    if (status === 'success') {
        toast.success(message, {
            position: isMobile ? "top-center" : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else {
        toast.error(message, {
            position: isMobile ? "top-center" : "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}