import { useContext, useEffect, useState } from "react";
import { Box, Button, Card, Divider, Skeleton, Typography } from "@mui/material"
import ColumnSynonimContent from "../ColumnSynonimContent";
import IdentifiedAccountsContent from "../IdentifiedAccountsContent";
import ProcessFileContext from "../../../contexts/processFileContext";
import StepperContext from "../../../contexts/stepperContext";
import { ClipLoader } from "react-spinners";
import { processFile as processFileAPI } from "../../../utils/api";

const PreProcessingContentSkeleton = () => {
    return (
        <>
            <Typography variant="h5"><Skeleton width={300} /></Typography>
            <Box mb={4} />
            <Typography variant="body1"><Skeleton width={800} /></Typography>
            <Box mb={4} />
            <Skeleton width={800} height={5} />
            <Box mb={4} />
            <Typography variant="h5"><Skeleton width={300} /></Typography>
            <Skeleton width={800} height={200} />
            <Box mb={4} />
            <Skeleton width={800} height={50} />
        </>
    )
}

const PreProcessingContent = () => {
    const { missingColumns, identifiedAccounts, identifyAccounts, uploadedFilePath, processingSteps, setProcessedFilename, setResponseFileURL, isLoading, setIsLoading } = useContext(ProcessFileContext)
    const { goNext } = useContext(StepperContext)

    const parseFilename = (filename) => {
        const regex = /filename\*\=utf-8''(.+)/;

        const match = filename.match(regex);

        if (match && match[1]) {
            const fileName = decodeURIComponent(match[1]);
            return fileName
        } else {
            return 'arquivo_processado'
        }
    }

    const processFile = () => {
        setIsLoading(true)
        processFileAPI(uploadedFilePath, processingSteps).then(response => {
            console.log(response)
            const file = new Blob([response.data], { type: response.data.type });
            const fileURL = URL.createObjectURL(file);
            setProcessedFilename(parseFilename(response.headers['content-disposition']))
            setResponseFileURL(fileURL)
            setIsLoading(false)
            goNext()
        }).catch(({ response }) => {
            console.log(response)
            setIsLoading(false)
        })
    }

    const handleProcessFileClick = () => {
        processFile()
    }

    useEffect(() => {
        identifyAccounts()
    }, [])

    return (
        <Card sx={{ padding: "48px", borderRadius: "8px" }}>
            {isLoading ?
                <PreProcessingContentSkeleton />
                :
                <>
                    <ColumnSynonimContent />
                    <Box mb={6} />
                    <Divider />
                    <Box mb={6} />
                    <IdentifiedAccountsContent />
                    {missingColumns.length === 0 && Object.values(identifiedAccounts).length === 0 &&
                        <>
                            <Box mb={8} />
                            <Button variant="contained" color="primary" disabled={isLoading} onClick={handleProcessFileClick}>
                                {isLoading ? <>
                                    <ClipLoader size={18} color="white" />
                                    <Box ml={3} />
                                    Aguarde um momento...
                                </> : "Iniciar o Processamento"}

                            </Button>
                        </>
                    }
                </>
            }
        </Card>
    )
}

export default PreProcessingContent;