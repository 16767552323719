import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    InputBase,
    Paper,
    Toolbar,
    Typography,
    Avatar,
    CircularProgress,
    Popover,
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@mui/material';
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { listUsers, deleteUser } from "../../utils/api";
import { ToastContainer, toast } from 'react-toastify';
import { UserTypeEnum, translateUserType } from '../../enums/userType';
import 'react-toastify/dist/ReactToastify.css';
import './list-users.scss';
import { createNotification } from '../../utils/alerts';
import MainLayout from "../../layouts";

let timeoutId;

const ListUsers = () => {
    const isMobile = window.innerWidth < 800;
    const [searchTerm, setSearchTerm] = useState('');
    const [searchUserType, setSearchUserType] = useState('Todos');
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [userToDelete, setUserToDelete] = useState(null);
    const [emptyUser, setEmptyUsers] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const getUsers = async (searchTerm, searchUserType) => {
        try {
            setLoading(true);

            const response = await listUsers({
                per_page: itemsPerPage,
                search: searchTerm === '' ? undefined : searchTerm,
                user_type: searchUserType === 'Todos' ? undefined : searchUserType,
            });

            setUsers(response.users);
            setEmptyUsers(response.users.length == 0)
            setPage(1)
        } catch (error) {
            createNotification('error', 'Erro ao buscar usuários', isMobile);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const loadMoreUsers = useCallback(async () => {
        if (loading || emptyUser) return;
        setLoading(true);
        const nextPage = page + 1;

        const response = await listUsers({
            search: searchTerm,
            userType: searchUserType === 'Todos' ? undefined : searchUserType,
            page: nextPage,
            perPage: itemsPerPage
        });

        setTimeout(() => {
            setUsers(prevUsers => [...prevUsers, ...response.users]);
            setPage(nextPage);
            setLoading(false);
            setEmptyUsers(response.users.length == 0)
        }, 500);
    });

    const handleSearchChange = (event) => {
        clearTimeout(timeoutId);
        setSearchTerm(event.target.value);

        timeoutId = setTimeout(() => {
            getUsers(event.target.value, searchUserType);
        }, 1000);
    };

    const handleUserTypeChange = (event) => {
        setSearchUserType(event.target.value);
        getUsers(searchTerm, event.target.value);
    };

    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
        if (bottom) {
            loadMoreUsers();
        }
    };

    const handleDelete = (event, user) => {
        setAnchorEl(event.currentTarget);
        setUserToDelete(user);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setUserToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (userToDelete) {
            await deleteUser(userToDelete.id);
            setUsers(users.filter(user => user.id !== userToDelete.id));
            createNotification('success', 'Usuário excluído!', isMobile);
        }
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <MainLayout>
            <Container className="container" maxWidth="" >
                <AppBar position="static" className="appbar">
                    <Toolbar className="toolbar">
                        {isMobile && (
                            <>
                                <Button
                                    variant="contained"
                                    className="add-button"
                                    startIcon={<AddIcon />}
                                    component={Link}
                                    to="/users/register"
                                >
                                    Cadastrar
                                </Button>
                                <Box className="search-box">
                                    <InputBase
                                        className="search-input"
                                        placeholder="Pesquisar..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        inputProps={{ 'aria-label': 'pesquisar' }}
                                        startAdornment={<SearchIcon />}
                                    />
                                    <FormControl className="user-type-select">
                                        <InputLabel id="user-type-label">Tipo de Usuário</InputLabel>
                                        <Select
                                            labelId="user-type-label"
                                            id="user-type"
                                            value={searchUserType}
                                            onChange={handleUserTypeChange}
                                        >
                                            <MenuItem key='0' value='Todos'>Todos</MenuItem>
                                            {Object.keys(UserTypeEnum).map((key) => (
                                                <MenuItem key={key} value={UserTypeEnum[key]}>
                                                    {translateUserType(UserTypeEnum[key])}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </>
                        )}
                        {!isMobile && (
                            <>
                                <Box className="search-box">
                                    <InputBase
                                        className="search-input"
                                        placeholder="Pesquisar..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        inputProps={{ 'aria-label': 'pesquisar' }}
                                        startAdornment={<SearchIcon />}
                                    />
                                    <FormControl className="user-type-select">
                                        <InputLabel id="user-type-label">Tipo de Usuário</InputLabel>
                                        <Select
                                            labelId="user-type-label"
                                            id="user-type"
                                            value={searchUserType}
                                            onChange={handleUserTypeChange}
                                        >
                                            <MenuItem key='0' value='Todos'>Todos</MenuItem>
                                            {Object.keys(UserTypeEnum).map((key) => (
                                                <MenuItem key={key} value={UserTypeEnum[key]}>
                                                    {translateUserType(UserTypeEnum[key])}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Button
                                    variant="contained"
                                    className="add-button"
                                    startIcon={<AddIcon />}
                                    component={Link}
                                    to="/users/register"
                                >
                                    Cadastrar
                                </Button>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
                <Box mt={2} className="user-list" onScroll={handleScroll}>
                    {users.length === 0 && !loading && (
                        <Typography variant="body1" align="center" sx={{ mt: 2 }}>
                            Nenhum usuário encontrado
                        </Typography>
                    )}
                    {users.map(user => (
                        <Paper key={user.id} className="user-card">
                            <Avatar className="user-card-avatar" src={user.thumb_link ?? '../../assets/default_user.png'}></Avatar>
                            <Box className="user-card-info">
                                <Typography variant="h6">{user.name}</Typography>
                                <Typography variant="body2">{user.email}</Typography>
                                <Typography variant="body2">{translateUserType(user.user_type)}</Typography>
                            </Box>
                            <IconButton className="edit-button" aria-label="edit" onClick={() => { window.location.href = `/users/${user.id}` }}>
                                <EditIcon />
                            </IconButton>
                            <IconButton className="delete-button" aria-label="delete" onClick={(e) => handleDelete(e, user)}>
                                <DeleteIcon />
                            </IconButton>
                        </Paper>
                    ))}
                    {loading && <CircularProgress sx={{ display: 'block', mx: 'auto' }} />}
                </Box>
            </Container>

            <Popover
                id={id}
                className="popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className="popover-box">Você realmente deseja excluir este registro?</Typography>
                <Box className="popover-buttons">
                    <Button onClick={handleClose} className="cancel-button">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmDelete} className="confirm-button">
                        Excluir
                    </Button>
                </Box>
            </Popover>

            <ToastContainer />
        </MainLayout>
    );
};

export default ListUsers;
