import React, { createContext, useState } from 'react';

const StepperContext = createContext();

export const StepperContextProvider = ({ children }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [labels, setLabels] = useState([])
    const [contents, setContents] = useState([])

    function goNext() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    function goBack() {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const resetStepper = () => {
        setActiveStep(0);
    };

    return (
        <StepperContext.Provider value={{ goNext, goBack, resetStepper, activeStep, labels, setLabels, contents, setContents }}>
            {children}
        </StepperContext.Provider>
    );
};

export default StepperContext;