import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
// import MainPage from './pages/main';
import MainPage from './pages/main/new-main';
import LedgerPage from './pages/ledger-accounts';
import ColumnSynonymsPage from './pages/column_synonyms';
import OutLedgerAccountsPage from './pages/out_ledger_accounts';
import LoginPage from './pages/login';
import { AuthProvider } from './contexts/authContext';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import ListUsers from './pages/users';
import UserPage from './pages/users/user.js';

import { isAdmin } from './utils/userData.js';

function Router() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/login' element={<PublicRoute restricted={true} element={<LoginPage />} />} />
          <Route path='/' element={<PrivateRoute element={<MainPage />} />} />
          <Route path='/ledger-accounts' element={<PrivateRoute element={<LedgerPage />} />} />
          <Route path='/column-synonyms' element={<PrivateRoute element={<ColumnSynonymsPage />} />} />
          <Route path='/out-ledger-accounts' element={<PrivateRoute element={<OutLedgerAccountsPage />} />} />

          {isAdmin() && (
            <>
              <Route path="/users" element={<PrivateRoute element={<ListUsers />} />} />
              <Route path="/users/register" element={<PrivateRoute element={<UserPage />} />} />
              <Route path="/users/:userId" element={<PrivateRoute element={<UserPage />} />} />
            </>
          )}

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default Router;
